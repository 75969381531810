import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  selectGlobalFont ,
  selectHeadlineFont,
  selectParagraphFont,
  selectInputFont,
  selectButtonFont,
  setGlobalFont,
  setHeadlineFont,
  setParagraphFont,
  setInputFont,
  setButtonFont,
} from '../../../../../../store/reducers/forms';
import { organizeAndSortFonts } from '../../../../../../utils/fonts'
import settingsStyles from '../../../Components/ModalSettings/ModalSettings.module.scss';
import Dropdown from '../../../Components/Dropdown';

const partialStyles = {
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.data.value === null ? '#90A0B7' : baseStyles.color,
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: state.data.value === null ? '#90A0B7' : baseStyles.color,
  }),
};

const FontSettings = ({ fontsData }) => {
  const dispatch = useDispatch();
  const [fonts, setFonts] = useState([]);
  const [partialFonts, setPartialFonts] = useState([]);
  const [isDataProcessing, setIsDataProcessing] = useState(false);
  const globalFont = useSelector(selectGlobalFont);
  const headlineFont = useSelector(selectHeadlineFont);
  const paragraphFont = useSelector(selectParagraphFont);
  const inputFont = useSelector(selectInputFont);
  const buttonFont = useSelector(selectButtonFont);
  const globalFontValue = fonts.find((font) => font.value === globalFont);
  const headlineFontValue = partialFonts.find((font) => font.value === headlineFont);
  const paragraphFontValue = partialFonts.find((font) => font.value === paragraphFont);
  const inputFontValue = partialFonts.find((font) => font.value === inputFont);
  const buttonFontValue = partialFonts.find((font) => font.value === buttonFont);

  const isLoading = isDataProcessing;

  useEffect(() => {
    if (fontsData?.fonts?.length > 0) {
      setIsDataProcessing(true);
      const sortedFonts = organizeAndSortFonts(fontsData?.fonts);
      
      const fontList = sortedFonts.map((font) => ({
        label: font,
        value: font
      }));
      
      setFonts(fontList);
      setPartialFonts([
        {
          label: 'Use global',
          value: null
        }, 
        ...fontList
      ])
      setIsDataProcessing(false);
    } else {
      setFonts([]);
      setPartialFonts([]);
    }
  }, [fontsData])

  const handleFontChange = (newVal, type) => {
    switch (type) {
      case 'headline':
        dispatch(setHeadlineFont(newVal.value))
        break;
      case 'paragraph':
        dispatch(setParagraphFont(newVal.value))
        break;
      case 'input':
        dispatch(setInputFont(newVal.value))
        break;
      case 'button':
        dispatch(setButtonFont(newVal.value))
        break;
      default:
        dispatch(setGlobalFont(newVal.value))
        break;
    }
  }

  return (
    <>
      <div className={settingsStyles.section_title}>
        Fonts
      </div>

      <Dropdown 
        title="Global font" 
        options={fonts} 
        onChange={handleFontChange}
        isLoading={isLoading}
        value={globalFontValue}
        fullWidth
        isSearchable={false}
      />

      <Dropdown 
        title="Headline font" 
        options={partialFonts} 
        onChange={(newVal) => handleFontChange(newVal, 'headline')}
        isLoading={isLoading}
        value={headlineFontValue}
        fullWidth
        styles={partialStyles}
        isSearchable={false}
      />

      <Dropdown 
        title="Paragraph font" 
        options={partialFonts} 
        onChange={(newVal) => handleFontChange(newVal, 'paragraph')}
        isLoading={isLoading}
        value={paragraphFontValue}
        fullWidth
        styles={partialStyles}
        isSearchable={false}
      />

      <Dropdown 
        title="Input font" 
        options={partialFonts} 
        onChange={(newVal) => handleFontChange(newVal, 'input')}
        isLoading={isLoading}
        value={inputFontValue}
        fullWidth
        styles={partialStyles}
        isSearchable={false}
      />

      <Dropdown 
        title="Button font" 
        options={partialFonts} 
        onChange={(newVal) => handleFontChange(newVal, 'button')}
        isLoading={isLoading}
        value={buttonFontValue}
        fullWidth
        styles={partialStyles}
        isSearchable={false}
      />
    </>
  )
}

export default FontSettings
