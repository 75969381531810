import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Headline } from '@absolutelabs/react-component-library';
import {
  selectCurrPage,
  selectFieldSettings,
  selectFormTextColor,
} from '../../../../../store/reducers/forms';
import useFont from '../../../../../components/hooks/useFont';

const HeadlineText = ({ ...props }) => {
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const globalTextColor = useSelector(selectFormTextColor);
  const [fieldConfig, setFieldConfig] = useState({});
  const { font } = useFont('headline');

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    const config = currPageSettings?.fields?.find((x) => x.field_id === props?.field?.id);
    if (config) {
      setFieldConfig(config);
    }
  }, [fieldSettings, currPage, props?.field?.id]);

  return (
    <Headline
      label={fieldConfig.label || 'Headline'}
      textAlignment={fieldConfig.textAlignment || 'left'}
      styleTag={fieldConfig.styleTag || 'h1'}
      textColor={globalTextColor}
      font={font}
    />
  );
};

export default HeadlineText;
