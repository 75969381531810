import React from 'react';
import Select from 'react-select';
import {
  FormatGroupLabel, MultiValueLabel, MultiValueRemove,
} from '../labels';
import { placeholderStyle } from '../../Condition/Condition.constants';

const MultiSelect = ({
  disabled, value, options, onChange, isLoading, getOptionLabel
}) => {
  const handleChange = (val) => {
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <div className="w-100">
      <Select
        styles={placeholderStyle}
        maxMenuHeight={194}
        components={
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            MultiValueRemove: (props) => <MultiValueRemove props={props} show="label" />,
            // eslint-disable-next-line react/no-unstable-nested-components
            MultiValueLabel: (props) => <MultiValueLabel props={props} show="label" />,
          }
        }
        getOptionLabel={getOptionLabel}
        disabled={disabled}
        isDisabled={disabled}
        className="w-100"
        formatGroupLabel={FormatGroupLabel}
        value={value}
        options={options}
        hideSelectedOptions={false}
        isSearchable
        onChange={handleChange}
        isShowValue
        isMulti
        isLoading={isLoading}
      />
    </div>
  );
};

export default MultiSelect;
