import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { getUrl, parseData } from '../../helper';
import { debounce } from '../../../../utils/debounce';
import { placeholderStyle } from '../../../AddSegment/Condition/Condition.constants';
import styles from './Dropdown.module.scss';

const Dropdown = ({ type, label, defaultItem, getter, setter }) => {
  const dispatch = useDispatch();
  const selectedItem = useSelector(getter);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData('').then(initialData => {
      setDefaultOptions(initialData || []);
    });
  }, []); 

  useEffect(() => {
    if (defaultItem && !selectedItem) {
      dispatch(setter(defaultItem));
    }
  }, [defaultItem])

  const getData = useCallback(async (val) => {
    setIsLoading(true);
    const handler = getUrl(type, val);
    const apiData = await dispatch(
      handler.url.initiate(
        typeof handler.value === 'string' ? handler.value : { ...handler.value },
        { forceRefetch: 10 },
      ),
    );
    const parsedData = parseData(apiData, type);

    if (parsedData && parsedData.length === 1) {
      dispatch(setter(parsedData[0]));
    }

    setIsLoading(false);
    return parsedData;
  }, [dispatch]);

  const loadOptions = useCallback(debounce((val) => getData(val), 1000), [getData]);

  const handleChange = (newValue) => {
    dispatch(setter(newValue));
  }

  return (
    <div className={styles.dropdown}>
      <span className={styles.label}>{label}</span>
      <AsyncSelect
        styles={placeholderStyle}
        placeholder={`Select ${label.toLowerCase()}...`}
        maxMenuHeight={194}
        value={selectedItem}
        loadOptions={loadOptions}
        onChange={(val) => handleChange(val)}
        isSearchable
        defaultOptions={defaultOptions}
        isLoading={isLoading}
      />
    </div>
  )
}

export default Dropdown
