import React from 'react';
import styles from './ParagraphSettings.module.scss';

export const sizeOptions = [
  {
    value: 'XS',
    elem: <span className={styles.size_button}>XS</span>,
  },
  {
    value: 'S',
    elem: <span className={styles.size_button}>S</span>,
  },
  {
    value: 'M',
    elem: <span className={styles.size_button}>M</span>,
  },
  {
    value: 'L',
    elem: <span className={styles.size_button}>L</span>,
  },
  {
    value: 'XL',
    elem: <span className={styles.size_button}>XL</span>,
  },
  {
    value: 'XXL',
    elem: <span className={styles.size_button}>XXL</span>,
  },
];
