import { useLocation } from 'react-router-dom';
import useWebSocket from './app/useWebSocket';
import { useMemo } from 'react';

export const useAiOverview = ({
  isReady,
  type = 'token',
  address,
  network,
  filterOption = null,
  isCustomContract = false,
  market = false
}) => {
  const { pathname } = useLocation();
  const name = pathname.split('/')[2];

  const shouldConnect = useMemo(() => {
    if (market) {
      return type === 'coin' ? isReady : isReady && !isCustomContract;
    }
    return type === 'token'
      ? (isReady && filterOption !== 'all')
      : (isReady && !['relevant', 'all'].includes(filterOption) && !isCustomContract);
  }, [market, type, isReady, isCustomContract, filterOption]);

  const url = useMemo(() => {
    if (!shouldConnect) return null;
    
    if (market) {
      return type === 'coin' ? 'ws/ft/ai-overview/' : 'ws/nft/ai-overview/';
    }
    return type === 'token' 
      ? 'ws/ft/audience-profile/ai-overview/'
      : 'ws/nft/audience-profile/ai-overview/';
  }, [shouldConnect, market, type]);

  const payload = useMemo(() => {
    if (!shouldConnect || (type === 'nft' && ((!market && !address) || !network))) return null;

    if (market) {
      return type === 'coin'
        ? { coin_id: name }
        : { address: name, blockchain: network };
    }
    return type === 'token'
      ? { coin_id: name, audience: filterOption }
      : { address, blockchain: network, audience: filterOption };
  }, [shouldConnect, market, type, name, network, address, filterOption]);

  const { aiOverview } = useWebSocket(url, payload, !shouldConnect);
  
  return aiOverview;
};