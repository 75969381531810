import React from 'react'
import AiBlock from '../../AiBlock'

const AiMarket = ({ itemName, aiOverview }) => (
  <AiBlock
    data={aiOverview}
    linkText="Full AI-Generated Report"
    reportTitle={`AI Summary of ${itemName}`}
    wBackground
    title="AI Overview -"
  />
);

export default AiMarket
