import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { localeStorageCache } from '../../../utils/localeStorage';
import { showErrorMessage } from '../../base/Notifications';

const getAuthToken = () => {
  const authKey = localeStorageCache.getAuth0Key();
  const authData = localeStorageCache.get(authKey);

  if (authData && authData.body) {
    const { id_token: idToken } = authData.body;
    return idToken;
  }
  return null;
};

const useWebSocket = (url, payload, skip) => {
  const [data, setData] = useState([]);
  const [fullMessage, setFullMessage] = useState('');
  const [aiOverview, setAiOverview] = useState({
    data: null,
    fullJSONMessage: null,
  });
  const socketRef = useRef(null);
  const messageBuffer = useRef('');
  const prevUrlRef = useRef(null);
  const prevPayloadRef = useRef(null);

  const closeConnection = useCallback(() => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.close();
      socketRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (skip) {
      closeConnection();
      return;
    }

    const token = getAuthToken();
    const baseURL = import.meta.env.VITE_APP_SERVER_DOMAIN;
    const urlfull = url ? `${baseURL.startsWith('https') ? baseURL.replace('https', 'wss')
      : baseURL.replace('http', 'ws')}${url}` : null;

    const hasUrlChanged = url !== prevUrlRef.current;
    const hasPayloadChanged = JSON.stringify(payload) !== JSON.stringify(prevPayloadRef.current);

    prevUrlRef.current = url;
    prevPayloadRef.current = payload;

    if (!url || !payload || !token || (!hasUrlChanged && !hasPayloadChanged)) {
      return;
    }

    if (hasUrlChanged || hasPayloadChanged) {
      closeConnection();
      setData([]);
      setFullMessage('');
      messageBuffer.current = '';
    }

    const socket = new WebSocket(urlfull, ['Authorization', token]);
    socketRef.current = socket;
    
    socket.onopen = () => {
      socket.send(JSON.stringify(payload));
    };

    socket.onmessage = (event) => {
      const messageData = event.data;
      messageBuffer.current += messageData;
      setData((prevData) => [...prevData, messageData]);
      if (messageBuffer.current.trim().endsWith('</full_analysis>')) {
        setFullMessage(messageBuffer.current);
      }
    };

    socket.onerror = () => {
      showErrorMessage('There was an error generating the AI Overview. Try again later');
    };

    socket.onclose = () => {
      setFullMessage(messageBuffer.current);
    };

    return () => {
      closeConnection();
    };
  }, [url, payload, skip, closeConnection]);

  useEffect(() => {
    if (data?.length > 0 && !fullMessage) {
      setAiOverview((prev) => ({
        ...prev,
        data,
      }));
    } else if (fullMessage) {
      setAiOverview((prev) => ({
        ...prev,
        fullJSONMessage: fullMessage,
      }));
    } else {
      setAiOverview({
        data: null,
        fullJSONMessage: null,
      });
    }
  }, [data, fullMessage]);

  return { aiOverview };
};

export default useWebSocket;
