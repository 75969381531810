import React, { useEffect, useRef, useState } from 'react';
import Chevron from '../../../../../assets/icons/chevron_down_raw.svg';
import styles from './WeightButton.module.scss';

const defaultWeight = {
  label: 'Normal',
  value: 400
}

const multipleWeight = {
  label: '--------',
  value: null
}

const WeightButton = ({ weights, quill }) => {
  const modalRef = useRef();
  const [isOpened, setIsOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultWeight);

  useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setIsOpened(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

  useEffect(() => {
    const instance = quill.getEditor();

    const handleSelectionChange = (range, oldRange) => {
      if (range === null && oldRange !== null) {
        setSelectedItem(defaultWeight);
      } else if (range) {
          const formats = instance.getFormat(range.index, range.length);
          if (formats && formats['font-weight']) {
            if (typeof formats['font-weight'] === 'string') {
              const weight = Number(formats['font-weight']);
              const weightData = weights.find((w) => w.value === weight);
              setSelectedItem(weightData);
            } else {
              setSelectedItem(multipleWeight)
            }
          } else {
            setSelectedItem(defaultWeight);
          }
      }
    };

    instance.on("selection-change", handleSelectionChange);

    return () => {
      instance.off("selection-change", handleSelectionChange);
    };
  }, [])

  useEffect(() => {
    if (weights?.length > 0) {
      setSelectedItem(weights.find((w) => w.value === 400) || defaultWeight)
    }
  }, [weights])

  const onItemClick = (newVal) => {
    setSelectedItem(newVal);
    setIsOpened(false);
    const quillInstance = quill.getEditor();
    const range = quillInstance.getSelection();

    if (range && range.length > 0) {
      const newWeight = newVal.value === 400 ? false : newVal.value;
      quillInstance.formatText(range.index, range.length, 'font-weight', newWeight);
    }
  }

  return (
    <>
      <div 
        className={`${styles.button_text} ${isOpened ? styles.opened : ''}`} 
        onClick={() => setIsOpened(!isOpened)}
      >
        {weights?.length > 0 ? (
          selectedItem?.label
        ) : 'Normal'}
        <Chevron className={isOpened ? styles.rotate : ''} />
      </div>
      {isOpened &&
        <div className={styles.dropdown} ref={modalRef}>
          {weights.map((w) => (
            <button 
              type='button' 
              className={`item ${styles.item}`}
              onClick={() => onItemClick(w)}
            >
              {w.label}
            </button>
          ))}
        </div>
      }
    </>
  )
}

export default WeightButton;