import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentFolder,
  getSelectedItems,
} from '../../../../store/reducers/folders';
import emitter from '../../../../utils/emitter';
import { audiencesApi } from '../../../../api/audiences';
import { flowsApi } from '../../../../api/flows';
import { formsApi } from '../../../../api/forms';
import { foldersApi } from '../../../../api/folders';
import { showErrorMessage, showSuccessMessage } from '../../../base/Notifications';
import FolderInIcon from '../../../../assets/icons/folder_in.svg';
import FolderOutIcon from '../../../../assets/icons/folder_out.svg';
import TrashIcon from '../../../../assets/icons/trash_raw.svg';
import ConfirmModal from '../../modals/ConfirmModal';
import AddItemsToFoldersModal from '../../modals/Folders/AddItemsToFoldersModal';
import styles from './folderActions.module.scss';
import Tooltip from '../../Tooltip';
import { selectFlowStatusList } from '../../../../store/reducers/flows';

const FolderActions = ({ type }) => {
  const selection = useSelector((state) => getSelectedItems(state, type));
  const flowStatusList = useSelector(selectFlowStatusList);
  const [removeItemsFromFolder] = foldersApi.useRemoveItemsFromFolderMutation();
  const [deleteMultipleAudiences, resultDeleteMultipleAudiences] = audiencesApi.useDeleteMultipleAudiencesMutation();
  const [deleteMultipleFlows, resultDeleteMultipleFlows] = flowsApi.useDeleteMultipleFlowsMutation();
  const [deleteMultipleForms, resultDeleteMultipleForms] = formsApi.useDeleteMultipleFormsMutation();
  const [showAddItemsToFoldersModal, setShowAddItemsToFoldersModal] = useState(false);
  const [showRemoveItemsModal, setShowRemoveItemsModal] = useState(false);
  const [showDeleteItemsModal, setShowDeleteItemsModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const folder = useSelector((state) => getCurrentFolder(state, type));

  const refetch = () => {
    if (type === 'audiences') {
      emitter.emit('refetch_audiences');
    } else if (type === 'flows') {
      emitter.emit('refetch_flows');
    } else if (type === 'forms') {
      emitter.emit('refetch_forms');
    }
  };

  const handleRemoveItems = async () => {
    if (selection.length === 0) {
      showErrorMessage('Selection cannot be empty');
      return;
    }
    const result = await removeItemsFromFolder(
      {
        items: selection.map((i) => ({ object_id: i.id, object_type: i.type })),
        folders: [folder.id],
        type,
      },
    );
    if (result.error) {
      showErrorMessage(`Error during ${type} removal`);
      return;
    }
    showSuccessMessage(`Selected ${type} was successfully removed`);
    refetch();
    setShowRemoveItemsModal(false);
  };

  const handleDeleteItems = async () => {
    if (selection.length === 0) {
      showErrorMessage('Selection cannot be empty');
      return;
    }

    if (type === 'flows') {
      const selectionStatusList = selection.map((elem) => flowStatusList.find((flow) => flow.id === elem.id).status);
      if (selectionStatusList && selectionStatusList.includes('running')) {
        showErrorMessage('The selections you want to delete includes "running" flow');
        return;
      }
    }

    setIsDeleting(true);

    let audiencesInFlow = false;
    let formsDeletionErrorMessage = '';

    try {
      if (type === 'audiences') {
        const audiencesToDelete = selection.map((i) => ({
          id: i.id,
          type: i.type === 'audiences' ? 'segment' : 'custom_list',
        }));
        const result = await deleteMultipleAudiences(audiencesToDelete);
        if (result.error?.data?.message === "Custom lists can't be deleted. One of the custom list is used in flow") {
          audiencesInFlow = true;
        }
      } else if (type === 'flows') {
        const flowsToDelete = selection.map((i) => ({
          id: i.id,
          type: 'flow',
        }));
        await deleteMultipleFlows(flowsToDelete);
      } else if (type === 'forms') {
        const formsToDelete = selection.map((i) => ({
          id: i.id,
          type: 'form',
        }));
        const result = await deleteMultipleForms(formsToDelete);
        if (result.error?.status === 400) {
          formsDeletionErrorMessage = result.error.data?.detail;
        }
      }

      if (audiencesInFlow) {
        showErrorMessage("Audiences can't be deleted. One of the audience is used in flow");
        setShowDeleteItemsModal(false);
      } else if (formsDeletionErrorMessage) {
        showErrorMessage(formsDeletionErrorMessage);
        setShowDeleteItemsModal(false);
      } else {
        refetch();
        showSuccessMessage(`Selected ${type} was successfully deleted`);
        setShowDeleteItemsModal(false);
      }
    } catch (error) {
      showErrorMessage('Error during deletion');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      {showAddItemsToFoldersModal && (
        <AddItemsToFoldersModal
          closeModal={() => setShowAddItemsToFoldersModal(false)}
          type={type}
        />
      )}
      {showRemoveItemsModal && (
        <ConfirmModal
          title="Confirm removal"
          description={`Are you sure you want to remove selected ${type} from folder ${folder?.name || ''}?`}
          buttonName="Confirm"
          onCancel={() => setShowRemoveItemsModal(false)}
          onSubmit={handleRemoveItems}
          deleteMode
        />
      )}
      {showDeleteItemsModal && (
        <ConfirmModal
          title="Confirm deletion"
          description={`Are you sure you want to permanently delete selected ${type}?`}
          buttonName="Confirm"
          onCancel={() => setShowDeleteItemsModal(false)}
          onSubmit={handleDeleteItems}
          deleteMode
          loading={
            isDeleting
            || resultDeleteMultipleAudiences.isLoading
            || resultDeleteMultipleAudiences.isFetching
            || resultDeleteMultipleFlows.isLoading
            || resultDeleteMultipleFlows.isFetching
            || resultDeleteMultipleForms.isLoading
            || resultDeleteMultipleForms.isFetching
          }
        />
      )}
      <div className={styles.wrapper}>
        <div className={styles.text}>
          {selection.length}
          {' '}
          Selected
        </div>
        <div className={styles.icons}>
          <div data-for="add" data-tip>
            <button type="button" className={styles.icon} onClick={() => setShowAddItemsToFoldersModal(true)}>
              <FolderInIcon color="#323C47" />
              <Tooltip id="add" info={`Add selected ${type} to folders`} />
            </button>
          </div>
          {folder.id !== 'all' && folder.id !== 'my'
            ? (
              <div data-for="remove" data-tip>
                <button type="button" className={styles.icon} onClick={() => setShowRemoveItemsModal(true)}>
                  <FolderOutIcon color="#323C47" />
                  <Tooltip id="remove" info={`Remove selected ${type} from ${folder.name} folder`} />
                </button>
              </div>
            ) : null}
          <div data-for="delete" data-tip>
            <button type="button" className={styles.delete_icon} onClick={() => setShowDeleteItemsModal(true)}>
              <TrashIcon color="#323C47" />
              <Tooltip id="delete" info={`Delete selected ${type}`} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FolderActions;
