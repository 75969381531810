import { useSelector } from 'react-redux';
import {
  selectGlobalFont,
  selectHeadlineFont,
  selectParagraphFont,
  selectInputFont,
  selectButtonFont,
} from '../../store/reducers/forms';

const useFont = (type) => {
  const globalFont = useSelector(selectGlobalFont);
  const headlineFont = useSelector(selectHeadlineFont);
  const paragraphFont = useSelector(selectParagraphFont);
  const inputFont = useSelector(selectInputFont);
  const buttonFont = useSelector(selectButtonFont);

  const font = {
    button: buttonFont || globalFont,
    input: inputFont || globalFont,
    headline: headlineFont || globalFont,
    paragraph: paragraphFont || globalFont
  }[type] || globalFont; 

  return { font };
}

export default useFont;