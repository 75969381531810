import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/fonts';

export const fontsApi = createApi({
  reducerPath: 'fontsAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData && authData.body) {
        const { id_token: idToken } = authData.body;
        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Font'],
  endpoints: (build) => ({
    getFontsForTeam: build.query({
      query: () => ({
        url: `${BASE_API}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Font'],
    }),
  }),
});
