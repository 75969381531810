import React from 'react';
import Select from 'react-select';
import settingsStyles from '../ModalSettings/ModalSettings.module.scss';
import styles from './Dropdown.module.scss';

const dropdownStyles = {
  control: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: '#ffffff',
    height: '48px',
    borderRadius: '4px',
    border: 'solid 1px #c2cfe0',
  }),
};

const Dropdown = ({ 
  title, 
  options, 
  value, 
  onChange, 
  isLoading = false, 
  isSearchable = true, 
  fullWidth = false,
  styles: customStyles
}) => {
  const mergedStyles = (baseStyles, state, styleKey) => {
    const dropdownStyle = dropdownStyles[styleKey]?.(baseStyles, state) || baseStyles;
    const customStyle = customStyles?.[styleKey]?.(baseStyles, state) || {};
    return { ...dropdownStyle, ...customStyle };
  };

  const mergedSelectStyles = {
    control: (baseStyles, state) => mergedStyles(baseStyles, state, "control"),
    singleValue: (baseStyles, state) => mergedStyles(baseStyles, state, "singleValue"),
    option: (baseStyles, state) => mergedStyles(baseStyles, state, "option"),
  };

  const handleChange = (newValue) => {
    if (onChange) {
      onChange(newValue)
    }
  }

  return (
    <div className={`${styles.wrapper} ${fullWidth ? '' : styles.half}`}>
      <div className={settingsStyles.title}>{title}</div>
      <Select
        maxMenuHeight={194}
        styles={mergedSelectStyles}
        value={value}
        onChange={handleChange}
        options={options}
        isLoading={isLoading}
        isSearchable={isSearchable}
      />
    </div>
  )
}

export default Dropdown
