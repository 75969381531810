import React, { useCallback, useMemo } from 'react'
import { DateSelect, DefaultSelect } from '../selects';
import { removeValueFromOptions } from '../../../../store/reducers/segment';
import NumericInput from '../selects/NumericInput';
import MultiSelect from '../selects/MultiSelect';
import { useDispatch } from 'react-redux';
import WalletNameLabel from '../../../FormCampaigns/Components/WalletNameLabel';

const DevActivityControlForm = ({
  data,
  edit,
  pushSelectedOptionToArray,
}) => {
  const dispatch = useDispatch();
  const getValue = (name) => {
    const option = data.options.find((option) => option.name === name);

    if (!option) return undefined;
    const optionValue = option.value;

    if (!optionValue) return null;
    
    if (optionValue.length > 0) {
      return optionValue[0].value;
    }
    return null;
  }

  const shouldDisplayField = (fieldName) => {
    let parentValue;
    switch (fieldName) {
      case "deployment":
        return getValue("any-number") === "any-number";
      case "any-number-amount":
      case "deployed-contracts-duration":
        if (!shouldDisplayField("any-number")) return false;
        parentValue = getValue("any-number");
        return parentValue === "at-least" || parentValue === "at-most";
      case "is-than-combo":
        if (!shouldDisplayField("deployment")) return false;
        parentValue = getValue("deployment");
        return parentValue === "and-first-deployment" || parentValue === "and-last-deployment";
      case "date":
        if (!shouldDisplayField("is-than-combo")) return false;
        parentValue = getValue("is-than-combo");
        return parentValue === "is-before" || parentValue === "is-after";
      case "is-than-amount":
      case "ago":
        if (!shouldDisplayField("is-than-combo")) return false;
        parentValue = getValue("is-than-combo");
        return parentValue === "is-more-than" || parentValue === "is-less-than";
      case "chain":
        if (!shouldDisplayField("any-chain-or-any-of-or-all-of")) return false;
        parentValue = getValue("any-chain-or-any-of-or-all-of");
        return parentValue === "on-any-of" || parentValue === "on-all-of";
      default:
        return true;
    }
  };

  const getChainLabel = useCallback((val) => <WalletNameLabel val={val} />, []);

  return useMemo(
    () => data.options.map((option) => {
      if (!shouldDisplayField(option.name)) {
        dispatch(removeValueFromOptions({ id: data.id, option: option.name }));
        return null;
      }

      if (data.name === "has-developer-activity") {
        if (option.name === "yes-or-no" && option.value === undefined) {
          pushSelectedOptionToArray("true", option.name, option.type);
        }
      } else if (data.name === "has-deployed-contracts") {
        if (option.name === "any-number" && option.value === undefined) {
          pushSelectedOptionToArray('any-number', option.name, option.type);
        } else if (option.name === "deployed-contracts-duration" && option.value === undefined) {
          pushSelectedOptionToArray('anytime', option.name, option.type);
        } else if (option.name === "deployment" && option.value === undefined) {
          pushSelectedOptionToArray('anytime', option.name, option.type);
        } else if (option.name === "any-chain-or-any-of-or-all-of" && option.value === undefined) {
          pushSelectedOptionToArray('on-any-chain', option.name, option.type);
        } else if (option.name === "ago" && option.value === undefined) {
          pushSelectedOptionToArray('days', option.name, option.type);
        }
      }

      if (option.name === 'chain') {
        return (
          <MultiSelect
            key={option.name}
            disabled={!edit}
            value={option.value}
            options={option.options}
            onChange={(val) => {
              pushSelectedOptionToArray(val, option.name, option.type);
            }}
            getOptionLabel={(val) => getChainLabel(val)}
          />
        );
      }

      if (option.type === 'select') {
        return (
          <DefaultSelect
            key={option.name}
            option={option}
            pushSelectedOptionToArray={pushSelectedOptionToArray}
            edit={edit}
          />
        );
      }
      if (option.type === 'input') {
        let value = null;
        if (Array.isArray(option.value) && option.value.length > 0) {
          value = option.value[0].value;
        }

        return (
          <NumericInput
            key={option.name}
            value={value}
            onChange={(val) => {
              if (val === '') {
                dispatch(removeValueFromOptions({id: data.id, option: option.name}))
              } else if (val.length <= 18) {
                pushSelectedOptionToArray(val, option.name, option.type);
              }
            }}
            disabled={!edit}
          />
        );
      }

      if (option.type === 'date-picker') {
        return (
          <DateSelect
            key={option.name}
            option={option}
            pushSelectedOptionToArray={pushSelectedOptionToArray}
            edit={edit}
          />
        );
      }

      return null;
    }),
    [
      data,
      pushSelectedOptionToArray,
      edit,
      dispatch,
    ],
  );
}

export default DevActivityControlForm
