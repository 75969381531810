import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import ReactDOM from "react-dom";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  selectCurrPage, selectFieldSettings, selectParagraphFont, selectGlobalFont, setFieldSettings,
} from '../../../../../../store/reducers/forms';
import styles from './ParagraphSettings.module.scss';
import formStyles from '../../FormSettings.module.scss';
import ModalSettings from '../../../Components/ModalSettings';
import settingsStyles from '../../../Components/ModalSettings/ModalSettings.module.scss';
import SelectionButtons from '../../../../../../components/ui/SelectionButtons';
import { sizeOptions } from './options';
import { fontsApi } from '../../../../../../api/fonts';
import { createWeightList } from '../../../../../../utils/fonts';
import WeightButton from '../../../Components/WeightButton';

const Inline = Quill.import('blots/inline');
class FontWeightBlot extends Inline {
  static create(value) {
    const node = super.create();
    node.setAttribute('style', `font-weight: ${value}`);
    return node;
  }

  static formats(node) {
    return node.style.fontWeight || null;
  }
}

FontWeightBlot.blotName = 'font-weight';
FontWeightBlot.tagName = 'span';
Quill.register(FontWeightBlot);

const ParagraphSettings = ({ activeSettings }) => {
  const quillRef = useRef(null);
  const dispatch = useDispatch();
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [content, setContent] = useState('<p>This is a paragraph.</p>');
  const [align, setAlign] = useState('left');
  const [size, setSize] = useState(sizeOptions[2].value);
  const debouncedUpdateStoreRef = useRef();
  const [errors, setErrors] = useState({});
  const initialized = useRef(false);
  const globalFont = useSelector(selectGlobalFont);
  const paragraphFont = useSelector(selectParagraphFont);
  const font = paragraphFont|| globalFont;
  const [weights, setWeights] = useState([]);

  const { currentData: fontsData } = fontsApi.useGetFontsForTeamQuery();

  useEffect(() => {
    if (fontsData && fontsData.fonts?.length > 0) {
      const fontList = fontsData.fonts.filter((fd) => fd.name === font);
      const weightList = [...new Set(fontList.map((fd) => fd.weight))].sort((a, b) => a - b);
      setWeights(createWeightList(weightList));
    }
  }, [fontsData])

  const updateStore = useCallback(async () => {
    const obj = {
      name: 'paragraph',
      content,
      align,
      type: 'paragraph',
      size,
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [content, align, size, dispatch, activeSettings]);

  useEffect(() => {
    if (debouncedUpdateStoreRef.current) {
      debouncedUpdateStoreRef.current.cancel();
    }

    debouncedUpdateStoreRef.current = _.debounce(updateStore, 300);
    debouncedUpdateStoreRef.current();

    return () => {
      if (debouncedUpdateStoreRef.current) {
        debouncedUpdateStoreRef.current.cancel();
      }
    };
  }, [content, align, size, updateStore]);

  const handleContentChange = (value) => {
    const textContent = value.replace(/<[^>]+>/g, '');
    if (textContent.length <= 10000) {
      setContent(value);
      if (errors.content) {
        setErrors({});
      }
    } else {
      const truncatedContent = textContent.slice(0, 10000);
      setContent(truncatedContent);
      setErrors({ ...errors, content: 'Character limit of 10000 reached' });
    }
  };

  const formats = [
    'bold', 'italic', 'underline', 'list', 'link', 'align', 'font-weight'
  ];

  const modules = {
    toolbar: {
      container: [
          "bold", "italic", "underline",
          { list: "ordered" },
          { list: "bullet" },
          "link",
          { 'align': ['', 'center', 'right'] },
      ],
    },
  };
  const sizeClass = {
    XS: styles['text-xs'],
    S: styles['text-s'],
    M: styles['text-m'],
    L: styles['text-l'],
    XL: styles['text-xl'],
  }[size];

  const setOptions = (config) => {
    setContent(config.content);
    setAlign(config.align);
    setSize(config.size);
  };

  useEffect(() => {
    if (initialized.current) return;
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setOptions(config);
      } else {
        handleContentChange(content);
      }
    }
    initialized.current = true;
  }, [activeSettings, currPage, fieldSettings]);

  const handleClick = (e) => {
    if (!e.target.closest('.ql-container') && !e.target.closest('.ql-toolbar')) {
      if (quillRef.current) {
        quillRef.current.focus();
        const quill = quillRef.current.getEditor();
        quill.setSelection(quill.getLength());
      }
    }
  };

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const quillContainer = editor.root;
      quillContainer.style.fontFamily = font;
    }
  }, [font]);

  useEffect(() => {
    if (weights?.length > 2 && quillRef.current
    ) {
      const boldButton = document.querySelector(".ql-bold");
      if (boldButton) {
        const weightButtonContainer = document.createElement("button");
        weightButtonContainer.className = "ql-weight";
        boldButton.replaceWith(weightButtonContainer);
        ReactDOM.render(<WeightButton weights={weights} quill={quillRef.current} />, weightButtonContainer);
      }
    }
  }, [weights, quillRef.current]);

  return (
    <ModalSettings title="Paragraph Properties">
      <div className={styles.wrapper}>
        <div className={settingsStyles.title}>Size</div>
        <SelectionButtons
          buttons={sizeOptions}
          selectedValue={size}
          setSelectedValue={(value) => {
            setSize(value);
          }}
        />
      </div>
      <div className={styles.wrapper}>
        <div className={settingsStyles.title}>Text</div>
        <div role="none" onClick={handleClick}>
          <ReactQuill
            ref={quillRef}
            formats={formats}
            className={`${styles.quillContainer} ${sizeClass}`}
            theme="snow"
            value={content}
            onChange={handleContentChange}
            modules={modules}
          />
        </div>
        {errors.content && (
          <div className={`${formStyles.error}`}>
            {errors.content}
          </div>
        )}
      </div>
    </ModalSettings>
  );
};

export default ParagraphSettings;
