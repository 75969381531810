import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Phone } from '@absolutelabs/react-component-library';
import {
  selectCurrPage,
  selectFieldBackgroundColor,
  selectFieldBorderColor,
  selectFieldLightBorders,
  selectFieldPlaceholderColor,
  selectFieldSettings,
  selectFormTextColor,
} from '../../../../../store/reducers/forms';
import styles from './PhoneField.module.scss';
import useFont from '../../../../../components/hooks/useFont';

const PhoneField = ({ ...props }) => {
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const globalTextColor = useSelector(selectFormTextColor);
  const [fieldConfig, setFieldConfig] = useState({});
  const { font } = useFont('input');
    const fieldBackgroundColor = useSelector(selectFieldBackgroundColor);
    const placeholderColor = useSelector(selectFieldPlaceholderColor);
    const borderColor = useSelector(selectFieldBorderColor);
    const lightBorders = useSelector(selectFieldLightBorders);

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    const config = currPageSettings?.fields?.find((x) => x.field_id === props?.field?.id);
    if (config) {
      setFieldConfig(config);
    }
  }, [fieldSettings, currPage, props?.field?.id]);

  return (
    <div className={styles.phoneFieldWrapper}>
      <Phone
        label={fieldConfig.label || ''}
        placeholder={fieldConfig.placeholder || ''}
        subLabel={fieldConfig.subLabel || ''}
        required={fieldConfig.required || false}
        className="mb-3"
        textColor={globalTextColor}
        font={font}
        backgroundColor={fieldBackgroundColor}
        placeholderColor={placeholderColor}
        borderColor={borderColor}
        lightBorders={lightBorders}
      />
    </div>
  );
};

export default PhoneField;
