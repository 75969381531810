import React from 'react'
import { useSelector } from 'react-redux';
import { selectFilterOption } from '../../../store/reducers/coin';
import AiBlock from '../../../components/ui/AiBlock'

const subTitle = {
  current: 'Current Holders',
  new: 'Newcomers',
  lost: 'Churned Holders',
  relevant: 'Relevant Holders',
};

const AiAudience = ({ aiOverview }) => {
  const filterOption = useSelector(selectFilterOption);

  return (
    <AiBlock
      data={aiOverview}
      linkText="Read more"
      title={`Deep-Dive on ${subTitle[filterOption]} -`}
      wBackground
      isProfile
    />
  )
}

export default AiAudience
